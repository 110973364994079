@import 'colors.scss';
@import 'login.scss';
@import 'passwordRecovery.scss';
@import 'createaccount.scss';
@import 'header.scss';
@import 'default.scss';
@import 'footer.scss';
@import 'createclients.scss';
@import 'accounts.scss';
@import 'clienttags.scss';
@import 'clientslist.scss';
@import 'newsale.scss';
@import 'createcompaign.scss';
@import 'saleslist.scss';
@import 'saletags.scss';
@import 'checquelist.scss';
@import 'segmentlist.scss';
@import 'newsegment.scss';
@import 'newcampagnes.scss';
@import 'campagneslist.scss';
@import 'actions.scss';
@import 'editsale.scss';
@import 'dashboard.scss';
@import 'stats.scss';
@import 'onglet.scss';
@import 'dashboardClient.scss';
@import 'checkEnvoye.scss';
@import 'preview.scss';
@import 'smsvocalcampagne.scss';
@import 'smscampagnes.scss';
@import 'smsvocallist.scss';
@import 'smsvocaltest.scss';
@import 'smsvocalmessage.scss';
@import 'smsmobiletest.scss';
@import 'statscampagnes.scss';
@import 'newsms1.scss';
@import 'newsms2.scss';
@import 'newsms3.scss';
@import 'newsms4.scss';
@import 'newsms5.scss';
@import 'newsms6.scss';
@import 'importmessage1.scss';
@import 'importmessage2.scss';
@import 'importmessage3.scss';
@import 'importmessage4.scss';
@import 'importmessage5.scss';
@import 'selectionmodal.scss';
@import 'chooseFileModal.scss';
@import 'topfields.scss';
@import 'testSmsForCampagneModal.scss';
@import 'addclients.scss';

.primary-selection-bar {
    .ant-select-selection--single {
        //background: #d1215e;
        background: $dark-pink;
    }
    .ant-select-arrow svg {
        color: white;
    }
    .ant-select-selection__placeholder {
        font-weight: bold;
    }
    .ant-select-selection-selected-value {
        font-weight: bold;
        color: white;
    }
}

// .ant-calendar-selected-day .ant-calendar-date {
//     background: none;
// }

// .ant-calendar-disabled-cell.ant-calendar-selected-day
//     .ant-calendar-date::before {
//     // background: rgb(245, 245, 245) !important;
//     color: rgba(0, 0, 0, 0.25) !important;
//     background-color: white !important;
//     position: absolute;
//     top: -1px;
//     left: 5px;
//     width: 24px;
//     height: 24px;
//     // background: rgba(0, 0, 0, 0.1);
//     border-radius: 2px;
//     content: '';
// }

.ant-calendar-disabled-cell .ant-calendar-date {
    background: #f5f5f5 !important;
}

.ant-calendar-disabled-cell.ant-calendar-selected-day
    .ant-calendar-date::before {
    background: none !important;
}

.ant-calendar-disabled-cell-last-of-row .ant-calendar-date {
    background: rgb(245, 245, 245) !important;
}

.loader-wrapper {
    z-index: 10000 !important;
}

.message-box {
    ::-webkit-scrollbar {
        width: 2px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: #f1f1f1;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #888;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #555;
    }
}


.btn-button {
    display: inline-block;

    &.btn-button-medium {
        padding: 8px 16px;
    }

    &.btn-button-primary {
        color: #fff;
        background: $dark-pink;
        cursor: pointer;

        &:hover {
            background: #424449;
        }
    }
}

.outlined-selection {
    &.ant-select-open {
        .ant-select-selection:active {
                 outline: none !important;
    border: none !important;
    -webkit-box-shadow: 0 0 10px #e50051 !important;
        }

        .ant-select-selection:focus {
                 outline: none !important;
    border: none !important;
    -webkit-box-shadow: 0 0 10px #e50051 !important;
        }
        .ant-select-selection:hover {
                 outline: none !important;
    border: none !important;
    -webkit-box-shadow: 0 0 10px #e50051 !important;
        }
    }
}
